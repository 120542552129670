import { Component, OnInit, Input } from '@angular/core';
import { NgxPopupComponent } from '../ngx-popups/ngx-popups/ngx-popups';

import { Company } from '../../../models/company';
import { AlertService } from '../../../services/alert.service';
import { NON_OTHER_DOCUMENT_TYPES } from '../../../utils/constants';

import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { DocumentFileService } from '../../../services/document-file.service';
import { AnalysisService } from '../../../services/analysis.service';
import { Loan } from '@services/fincura-ng-client/model/loan';
import { ProposedDebt } from '@models/proposed-debt';
import { LoanDocuments } from '@services/fincura-ng-client';
import { DocumentType } from '@utils/enums';
import { CustomAttributeDefinition } from '@models/custom-attribute-definition';
import { CustomAttributeDefinitionService } from '@services/custom-attribute-definition.service';
import { CommonFunctions } from '@utils/common-functions';

type LoanDetailModalTab = 'documents' | 'details' | 'data' | 'compliance' | 'notes';

@Component({
  selector: 'app-loan-details-modal',
  templateUrl: './loan-details-modal.component.html',
  styleUrls: ['./loan-details-modal.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class LoanDetailsModalComponent implements OnInit {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Input() company: Company = null;
  @Input() loan: Loan = null;
  @Input() currency = 'USD'; // making input for multi currency support later on

  tab: LoanDetailModalTab = 'details';
  statementFiles: Array<LoanDocuments> = [];
  otherFiles: Array<LoanDocuments> = [];

  isLoading = false;
  subsArr$: Subscription[] = [];
  retrieveFilesError = false;
  isCovenantsFlagOn = false;
  allowDscrCellEditing = false; // just view, no editing
  scopes: Array<{ id: number }>  = [];
  proposedDebts: Array<ProposedDebt> = [];
  isFinancialsTableShown = true;
  customAttrDefinitions: CustomAttributeDefinition[] = [];
  customAttributesValues = {};

  constructor(
    private _alertService: AlertService,
    private _documentFileService: DocumentFileService,
    private _analysisService: AnalysisService,
    private _customAttributeDefinitionService: CustomAttributeDefinitionService,
    ) { }

  ngOnInit(): void {
    if (!this.company || !this.loan) {
      // error 404 redirect here
      return;
    }

    this.proposedDebts = [{
      name: this.loan.title,
      principal: this.loan.principal,
      interestRate: this.loan.interestRate,
      termMonths: this.loan.termMonths,
      amortizationMethod: this.loan.paymentType,
      amortizationOverrides: null
    }];

    this.scopes = this._analysisService.calculateScopes(this.loan.financials.templateItems, this.loan.financials.dataColumns, this.proposedDebts, this.loan.financials.dataOverrides);

    if (this.scopes.length === 0 && this.loan.financials.dataColumns.length === 0) {
      this.isFinancialsTableShown = false;
    }

    if (this.loan.documents.length > 0) {
      this.statementFiles = this.loan.documents.filter( document => !document.statementTypes.includes(DocumentType.Other));
      this.otherFiles = this.loan.documents.filter( document => document.statementTypes.includes(DocumentType.Other));
    }

    this.subsArr$.push(this._customAttributeDefinitionService.getCustomAttributesForModel('Loan').subscribe(customAttributeDefinitions => {
      this.customAttrDefinitions = customAttributeDefinitions;
      this.customAttrDefinitions.forEach( (definition) => {
        this.customAttributesValues[CommonFunctions.camelToSnake(definition.key)] = this.loan.customAttributes[CommonFunctions.snakeToCamel(definition.key)];
      });
    }));

  }

  changeTab(tabName: LoanDetailModalTab) {
    this.tab = tabName;
  }

  // will have to change this for uuid & the api document types
  downloadOriginal(doc) {
    this._documentFileService.getDownloadUrlForOriginalDocument(doc.id).subscribe(data => {
      const url = data.originalFileUrl;
      const link = document.createElement('a');
      link.href = url;
      link.download = doc.originalDocumentName;
      link.target = '_blank';
      link.dispatchEvent(new MouseEvent('click'));
    },
    error => {
      this._alertService.error(error.message);
    })
  }
}
