<div *ngIf="!isLoading; else loadingPanel" class="container period-selection-container">
  <div class="row">
    <div class="col col-12">
      <label for="referenceFrameSelect">Select Reference Period</label>
      <app-select2
        id="referenceFrameSelect"
        placeholder="Select"
        [data]="periodOptions"
        [options]="{minimumResultsForSearch: -1}"
        [value]="selectedReferenceFrameIndex"
        (valueChanged)="updateSelectedReferenceFrameIndex($event.value)"
        [width]="'100%'"
      >
      </app-select2>
    </div>
  </div>
  <div class="row padding-bottom-4">
    <div class="col col-12">
      <p class="consolidate-periods-form-header">Select the line item(s) to apply growth or decay to. Amounts can be positive or negative. Enter percentages as full numbers. </p>
    </div>
  </div>
  <div class="row padding-bottom-0">
    <div class="col col-5">
      <label>Line Item(s)</label>
    </div>
    <div class="col col-7">
      <label>Amount</label>
    </div>
  </div>
  <ng-container *ngFor="let taxonomyItemId of taxonomyItemIdsInGrowthRateMappings">
    <div class="row padding-top-0">
      <div class="col col-5 line-item-input">
        <app-select2
          id="{{'taxonomyItemSelection' + taxonomyItemId}}"
          placeholder="Select"
          [data]="taxonomyItemIdToGrowthRateMappings[taxonomyItemId].taxonomy_item_options"
          [value]="taxonomyItemId"
          (valueChanged)="updateSelectedTaxonomyItemId($event.value, taxonomyItemId)"
          [width]="'100%'"
        >
        </app-select2>
      </div>
      <div class="col col-6 line-item-input">
        <div class="input-group">
          <div class="input-group-addon">
            <select class="form-select" id="{{'growthTypeSelect' + taxonomyItemId}}" (change)="updateGrowthType($event.target.value, taxonomyItemId)">
              <ng-container *ngFor="let growthTypeOption of growthTypeOptions">
                <option [selected]="growthTypeOption.id == taxonomyItemIdToGrowthRateMappings[taxonomyItemId].growth_type"
                        value="{{growthTypeOption.id}}" >{{growthTypeOption.text}}</option>
              </ng-container>
            </select>
          </div>
          <input
            type="text"
            id="{{'growthValueSelect' + taxonomyItemId}}"

            class="form-control"
            [ngClass]="{ 'danger-border': !taxonomyItemIdToGrowthRateMappings[taxonomyItemId].value }"
            placeholder="{{ GROWTH_TYPE_TO_VALUE_PLACEHOLDER_TEXT_MAPPINGS[taxonomyItemIdToGrowthRateMappings[taxonomyItemId].growth_type] }}"
            [value]="taxonomyItemIdToGrowthRateMappings[taxonomyItemId].value"
            (input)="updateGrowthValue($event.target.value, taxonomyItemId)"
          >
        </div>
      </div>
      <div class="col col-1">
        <button class="btn btn-danger remove-item-btn" [ngClass]="{'disabled': taxonomyItemIdsInGrowthRateMappings.length === 1 }" [disabled]="taxonomyItemIdsInGrowthRateMappings.length === 1" (click)="removeLineItem(taxonomyItemId)"><i class="fas fa-trash"></i></button>
      </div>
    </div>
    <div
      class="col col-12"
      [ngClass]="{
        'invalid-value': !taxonomyItemIdToGrowthRateMappings[taxonomyItemId].is_value_valid
      }"
    >
      <p class="invalid-value-warning" *ngIf="!taxonomyItemIdToGrowthRateMappings[taxonomyItemId].is_value_valid">Please only enter numerical values</p>
    </div>
  </ng-container>
  <div class="row padding-top-and-bottom-4">
    <div class="col col-12">
      <div class="btn btn-link add-line-item-button" (click)="addLineItem()"><i class="fas fa-plus"></i>Add Line Item</div>
    </div>
  </div>
  <div class="row" *ngIf="selectedReferenceFrameIndex !== -1 && shouldAndCanCheckBalanceSheetBalance">
    <div class="col col-12">
      <ng-container *ngIf="!(check < CHECK_THRESHOLD && check > -CHECK_THRESHOLD)">
        <p class="balance-sheet-check wrong-balance"><i class="fas fa-warning"></i>Balance sheet check: <span id="check-balance">{{ numberFormattingService.formatNumber(check, "CURRENCY", 2, {'showDollarSign': true}) }}</span><span class="copy-balance" (click)="copyCheckToClipboard()">Copy</span></p>
      </ng-container>
      <ng-container *ngIf="check < CHECK_THRESHOLD && check > -CHECK_THRESHOLD">
        <p class="balance-sheet-check correct-balance"><i class="fas fa-check"></i>Balance sheet check: <span id="check-balance">{{ numberFormattingService.formatNumber(check, "CURRENCY", 2, {'showDollarSign': true}) }}</span></p>
      </ng-container>
    </div>
  </div>
  <div class="row padding-bottom-4">
    <div class="col col-12">
      <p class="add-consolidated-period-information-text">Add forecasted period information</p>
    </div>
  </div>
  <div class="row padding-bottom-4">
    <div class="col col-6 reporting-interval-select">
      <label for="reportingInterval">Select Reporting Interval</label>
      <app-select2
        id="reportingIntervalSelect"
        placeholder="Select"
        [data]="reportingIntervalOptions"
        [options]="{minimumResultsForSearch: -1}"
        [value]="reportingInterval"
        (valueChanged)="updateReportingInterval($event.value)"
        [width]="'100%'"
      >
      </app-select2>
    </div>
    <div class="col col-6 statement-date-select">
      <label for="statementDate">Select Statement Date</label>
      <app-input-calendar-component
        [initialDate]="statementDate"
        (dateEmitter)="updateStatementDate($event)"
      ></app-input-calendar-component>
    </div>
  </div>
  <div class="row padding-bottom-4">
    <div class="col col-6 projection-name-select">
      <div class="form-group">
        <label for="projectionName" class="control-label">Projection Name</label>
        <input [value]="projectionName" type="text" id="projectionName" class="form-control" placeholder="Projection Name" (input)="updateProjectionName($event.target.value)">
      </div>
    </div>
    <div class= "col col-6 currency-select">
      <label for="currencySelect">Select Currency</label>
       <span class="ps-2">
            <i class="fas fa-circle-question" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Use this to select the currency for this built statement. Note: Currencies will not be converted. If forecasting a
            period in a currency (e.g., USD) different than the reference period (e.g., CAD), amounts will be projected without conversion."></i>
       </span>
      <select class="form-select" (change)="changeCurrency($event.target.value)">
          <option *ngFor="let currencyOption of supportedCurrencies" [value]="currencyOption.key"
                  [selected]="currencyOption.key === this.currency">
              {{currencyOption.key}} ({{currencyOption.symbol}})
          </option>
      </select>
    </div>
  </div>
  <div class="d-flex justify-content-end footer">
      <button class="btn btn-link" (click)="triggerCloseGeneratePeriods()">Cancel</button>
      <button class="btn btn-primary" (click)="generateForecast()" [disabled]="!isFormComplete">
        <span class="build-statement-btn-label">Build Statement</span>
        <div *ngIf="isSubmitting" class="loader button-loader build-statement-btn-icon"></div>
      </button>
  </div>
</div>
<ng-template #loadingPanel>
    <div class="loading-panel-container loader__container">
        <div class="loader"></div>
    </div>
</ng-template>

