<div class="nav-modal">
    <div class="row">
        <div class="col-xs-3 left-wrapper">
            <!-- hijacked for bg color -->
        </div>
        <div class="col-xs-3 sticky-sidebar">
            <ul class="nav">
                <li (click)="changeTab('details')">Loan Details</li>
                <li (click)="changeTab('data')" *ngIf="isFinancialsTableShown">Financial Data</li>
                <li (click)="changeTab('compliance')">Compliance</li>
                <li (click)="changeTab('documents')">Documents</li>
                <!-- <li (click)="changeTab('notes')">Notes</li> -->
            </ul>
        </div>
        <div class="col-xs-9 modal-body">
            <div class="modal__close" (click)="popup.close();">
                <i class="fas fa-times"></i>
            </div>
            <div class="row">
                <div class="col-xs-10">
                    <h3>{{loan?.title}}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <ng-container *ngIf="isLoading">
                        <div class="loader__container">
                            <div class="loader"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoading && tab === 'details'">
                        <h4>Deal</h4>

                        <table class="table table-bordered">
                            <tr>
                                <td class="row-header">Principal</td>
                                <td>{{loan?.principal | currency: currency:'symbol':'0.0-0'}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Interest Accrued</td>
                                <td>{{loan?.interestAccrued | currency: currency:'symbol':'0.0-0'}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Interest Rate</td>
                                <td>{{(loan?.interestRate)  | number:'1.2-2' }}%</td>
                            </tr>
                            <tr>
                                <td class="row-header">Term</td>
                                <td>{{loan?.termMonths}} months</td>
                            </tr>
                            <tr>
                                <td class="row-header">Contract Type</td>
                                <td>{{loan?.contractType}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Collateral Type</td>
                                <td>{{loan?.collateralType}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Collateral Value</td>
                                <td>{{loan?.collateralValue | currency: currency:'symbol':'0.0-0'}}</td>
                            </tr>
                        </table>

                        <table class="table table-bordered">
                            <tr>
                                <td class="row-header">Payment Status</td>
                                <td>{{loan?.paymentStatus}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Loan Start Date</td>
                                <td>{{loan?.startDate}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Monthly Payment</td>
                                <td>{{loan?.monthlyPayment | currency: currency:'symbol':'0.0-0'}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Payments Remaining</td>
                                <td>{{loan?.paymentsRemaining}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Outstanding Principal</td>
                                <td>{{loan?.outstandingPrincipal | currency: currency:'symbol':'0.0-0'}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Maturity Date</td>
                                <td>{{loan?.lastPaymentDate}}</td>
                            </tr>
                            <ng-container *ngFor="let customAttrDefinition of customAttrDefinitions" class="row">
                                <ng-container *ngIf="customAttributesValues[customAttrDefinition.key]">
                                    <tr>
                                        <td class="row-header">{{customAttrDefinition.name}}</td>
                                        <td>
                                            {{customAttributesValues[customAttrDefinition.key]?.value}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </table>

                        <table class="table table-bordered">
                            <tr>
                                <td class="row-header">Loan to Value</td>
                                <td>{{loan?.loanToValue | number:'1.2-2'}}</td>
                            </tr>
                        </table>

                        <h4>Company</h4>
                        <table class="table table-bordered">
                            <tr>
                                <td class="row-header">Name</td>
                                <td>{{company.name}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Contact</td>
                                <td>{{loan?.borrowerInfo.email}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Address</td>
                                <td>{{loan?.borrowerInfo.address}}
                                    {{loan?.borrowerInfo.city}},
                                    {{loan?.borrowerInfo.state}}
                                    {{loan?.borrowerInfo.zipcode}}
                                </td>
                            </tr>
                            <tr>
                                <td class="row-header">Status</td>
                                <td>{{loan?.state}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Company Notes</td>
                                <td>{{loan?.borrowerInfo?.companyNotes}}</td>
                            </tr>
                        </table>

                        <table class="table table-bordered">
                            <tr>
                                <td class="row-header">Industry</td>
                                <td>{{loan?.borrowerInfo.industry}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">NAICS Code</td>
                                <td>{{loan?.borrowerInfo.naicsCode}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">D&B Score</td>
                                <td>{{loan?.borrowerInfo.dunBradstreetScore}}</td>
                            </tr>
                            <tr>
                                <td class="row-header">Paynet Score</td>
                                <td>{{loan?.borrowerInfo.paynetScore}}</td>
                            </tr>
                        </table>

                    </ng-container>
                    <ng-container *ngIf="!isLoading && tab === 'documents'">
                        <h4>Documents</h4>

                        <ng-container *ngIf="retrieveFilesError">
                            Something went wrong. We are currently looking into it.
                        </ng-container>

                        <h4>Financial Statements</h4>
                        <ng-container *ngIf="statementFiles.length > 0">
                            <table class="table table-bordered">
                                <tr>
                                    <th>File Name</th>
                                    <th>Statement Type</th>
                                    <th>Status</th>
                                    <th>Uploaded</th>
                                </tr>
                                <tr *ngFor="let statementFile of statementFiles">
                                    <td class="filename-cell">
                                        <!-- <a
                                            (click)="downloadOriginal(statementFile)"> -->{{statementFile.filename}} <!-- </a> -->
                                    </td>
                                    <td>type</td>
                                    <td>
                                        {{statementFile.status}}
                                    </td>
                                    <td>
                                        {{statementFile.createdDate | amFromUtc | amLocal | amDateFormat: 'MM/DD/YYYY' }}
                                    </td>
                                </tr>
                            </table>
                        </ng-container>

                        <ng-container *ngIf="statementFiles.length == 0">
                            No statements found
                        </ng-container>



                        <h4>Other Documents</h4>
                        <ng-container *ngIf="otherFiles.length > 0">
                            <table class="table table-bordered">
                                <tr>
                                    <th>File Name</th>
                                    <th>Statement Type</th>
                                    <th>Status</th>
                                    <th>Uploaded</th>
                                </tr>
                                <tr *ngFor="let otherFile of otherFiles">
                                    <td class="filename-cell">
                                        <!--  <a (click)="downloadOriginal(otherFile)"> -->{{otherFile.filename}} <!-- </a> -->
                                    </td>
                                    <td>type</td> <!-- likely Other for every one right now -->
                                    <td>
                                        {{otherFile.status}}
                                    </td>
                                    <td>
                                        {{otherFile.createdDate | amFromUtc | amLocal | amDateFormat: 'MM/DD/YYYY' }}
                                    </td>
                                </tr>
                            </table>
                        </ng-container>

                        <ng-container *ngIf="otherFiles.length == 0">
                            No other documents found
                        </ng-container>

                    </ng-container>

                    <ng-container *ngIf="!isLoading && tab === 'data'">
                        <h4>Financial Data</h4>

                        <app-debt-service-coverage-table [templateItems]="loan?.financials?.templateItems"
                            [columns]="loan?.financials?.dataColumns" [scopes]="scopes" [proposedDebts]="proposedDebts"
                            [dataOverrides]="loan?.financials?.dataOverrides"
                            [allowCellEditing]="allowDscrCellEditing">
                        </app-debt-service-coverage-table>
                    </ng-container>

                    <ng-container *ngIf="!isLoading && tab === 'compliance'">
                        <h4>Compliance</h4>

                        <table class="table table-bordered">
                            <tr>
                                <td class="row-header centered">
                                    <i class="fa fa-check-circle blue" *ngIf="loan?.complianceInfo?.ofacSanctions"></i>
                                    <i class="fa fa-times-circle red" *ngIf="!loan?.complianceInfo?.ofacSanctions"></i>
                                </td>
                                <td>OFAC Sanctions Search</td>
                            </tr>
                            <tr>
                                <td class="row-header centered">
                                    <i class="fa fa-check-circle blue" *ngIf="loan?.complianceInfo?.pacerBankruptcies"></i>
                                    <i class="fa fa-times-circle red" *ngIf="!loan?.complianceInfo?.pacerBankruptcies"></i>
                                </td>
                                <td>PACER Case Locator</td>
                            </tr>
                            <tr>
                                <td class="row-header centered">
                                    <i class="fa fa-check-circle blue" *ngIf="loan?.complianceInfo?.stateCourtClaims"></i>
                                    <i class="fa fa-times-circle red" *ngIf="!loan?.complianceInfo?.stateCourtClaims"></i>
                                </td>
                                <td>State Court Claims</td>
                            </tr>
                            <tr>
                                <td class="row-header centered">
                                    <i class="fa fa-check-circle blue" *ngIf="loan?.complianceInfo?.divisionsOfCorpReport"></i>
                                    <i class="fa fa-times-circle red" *ngIf="!loan?.complianceInfo?.divisionsOfCorpReport"></i>
                                </td>
                                <td>Divisions of Corporation</td>
                            </tr>
                        </table>

                        <ng-container *ngIf="isCovenantsFlagOn">
                            <h4>Covenants</h4>

                            <p>
                                List table of covenants and if each one is in compliance here.
                            </p>
                            <p>
                                If in compliance, black text is fine. If out of compliance, use red text
                            </p>
                            <p>
                                Link to view all covenants at bottom
                            </p>
                            <p>
                                I think we want to limit this short view to only 4 items
                            </p>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!isLoading && tab === 'notes'">
                        <h4>Notes</h4>
                    </ng-container>


                </div>
            </div>
            <div>

            </div>
        </div>
    </div>
</div>