import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FinancialCovenant } from '../models/financial-covenant';
import { NonFinancialCovenant } from '../models/non-financial-covenant';
import { Loan, LoanDocuments } from './fincura-ng-client';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class LoanService {
  constructor(private apiService: ApiService) { }


  getLoans(companyId: number): Observable<any> {
    const payload = {
      filter: {
        borrower_id_eq: companyId,
      }
    };

    return this.apiService.send('Post', `/api/loans/all`, payload).pipe(
      map(data => data.response.objects));
  }

  createLoan(loan: Loan, documents: Array<LoanDocuments>): Observable<any> {
    const loanObject = loan;
    loanObject.documents = documents;


    return this.apiService.send('Post', '/api/loans', loan).pipe(
      map(data => <Loan>data.response.objects.pop()));
  }


  createCovenant(companyId, name, periods, selectedTemplate): Observable<any>  {
    return this.apiService.send('Post', '/api/loans', { companyId, periods, name, analysisTemplateId: selectedTemplate }).pipe(map(data => data.response.objects.pop()));
  }

  createFinancialCovenant(financialCovenant: FinancialCovenant): Observable<any> {
    return this.apiService.send('Post', '/api/financial-covenant', financialCovenant).pipe(
      map(data => <FinancialCovenant>data.response.objects.pop()));
  }

  createNonFinancialCovenant(financialCovenant: NonFinancialCovenant): Observable<any> {
    return this.apiService.send('Post', '/api/non-financial-covenant', financialCovenant).pipe(
      map(data => <NonFinancialCovenant>data.response.objects.pop()));
  }

  displayFriendlyName(name: string): string {
    let displayFriendlyName = name;

    if (displayFriendlyName.length === 2) { // if 2 letters, assume all caps
      return displayFriendlyName.toUpperCase();
    }

    displayFriendlyName = displayFriendlyName.toLowerCase(); // first make all lowercase so all caps can get capitalized

    // eslint-disable-next-line prefer-const
    let i, frags = displayFriendlyName.split('_');
    for (i = 0; i < frags.length; i++) {
      if (frags[i].length === 2) { // make any 2 char word all upper
        frags[i] = frags[i].toUpperCase();
      }
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }

    const joinedFragments = frags.join(' ');

    return joinedFragments;
  }

  displayFriendlyPaymentTypeName(name: string): string {
    if (name === Loan.PaymentTypeEnum.PrincipalAndInterest) {
      return 'P&I';
    } else if (name === Loan.PaymentTypeEnum.PrincipalPlusInterest) {
      return 'P+I';
    } else if  (name === Loan.PaymentTypeEnum.IoPeriod) {
      return 'Interest Only';
    }

    return this.displayFriendlyName(name);
  }

  enumPaymentTypeFromFriendlyName(name: string): string {
    if (name === 'P&I') {
      return Loan.PaymentTypeEnum.PrincipalAndInterest;
    } else if (name === 'P+I') {
      return Loan.PaymentTypeEnum.PrincipalPlusInterest;
    // } else if (name === 'BALLON') {
    //   return Loan.PaymentTypeEnum.Ballon;
    } else if (name === 'Interest Only') {
      return Loan.PaymentTypeEnum.IoPeriod;
    }
  }

}
