import {Injectable, OnDestroy} from "@angular/core";
import {
  SessionTimeoutWarningModalComponent
} from "@components/shared/session-timeout-warning-modal/session-timeout-warning-modal.component";
import {NgxPopupService} from "@components/shared/ngx-popups/ngx-popups/services/ngx-popup.service";
import {forkJoin, fromEvent, merge, Subject, Subscription, timer} from 'rxjs';
import {auditTime, map, take, takeUntil} from "rxjs/operators";
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";
import {Router} from "@angular/router";
import {UserService} from "@services/user.service";
import {BankSettingsService} from "@services/bank-settings.service";
import {SharedDataService} from "@services/shared-data.service";
import {ApiService} from "@services/api.service";

export const SESSION_EXPIRATION_DATE_KEY = "user_session_expiry";
export const SESSION_IDLE_STATUS_KEY = "session_idle_status";

export enum SESSION_IDLE_STATES {
  ACTIVE = 'ACTIVE',
  WARNING = 'WARNING',
  LOGOUT = 'LOGOUT'
}

const TWO_MINUTES_IN_MILLISECONDS = 120000;
const INTERVAL_PERIOD_IN_MS = 2000;
const USER_INTERACTION_DEBOUNCE_TIME = 1500;


@Injectable({
  providedIn: 'root'
})
export class UserSessionTimeoutService implements OnDestroy {
  inactiveSessionTimeoutInMS: number = null;
  sessionExpirationWarningDurationInMS: number = TWO_MINUTES_IN_MILLISECONDS;
  interactionEventsSub: Subscription;
  unsubscribe$ = new Subject();
  popupRef: NgxPopupComponent;
  warningModalOpen: boolean = false;
  boundHandleStorageEvent;

  constructor(
    private _popupService: NgxPopupService,
    private _userService: UserService,
    private _bankSettingsService: BankSettingsService,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _apiService: ApiService,
  ) {
  }

  init() {
    this.destroyServiceIfEmbeddedModeDetected();
    this.boundHandleStorageEvent = this.handleStorageEvent.bind(this)
    const bankSettings$ = this._bankSettingsService.getUserSessionRelatedBankSettings()
      .pipe(take(1));
    const currentUser$ = this._userService.getCurrentUser()
      .pipe(take(1));

    // load necessary user info and bank settings. Join the obxs so the sub will fire when both calls have returned
    forkJoin([bankSettings$, currentUser$]).subscribe(([bankSettings, user]) => {
      const userSessionLifetimeSeconds = bankSettings?.userSessionLifetimeSeconds;
      const userSessionWarningDurationSeconds = bankSettings?.userSessionWarningDurationSeconds;
      // feature is disabled when bank setting is null or embedded mode is true
      if (userSessionLifetimeSeconds === null || this._sharedDataService.embeddedMode$.value || this._sharedDataService.embeddedWorkflow$.value !== null) {
        return
      }
      // convert seconds to milliseconds
      this.inactiveSessionTimeoutInMS = userSessionLifetimeSeconds * 1000;
      this.sessionExpirationWarningDurationInMS = userSessionWarningDurationSeconds * 1000;
      if (!user || user?.redisSessionIsExpired) {
        this.logoutUser();
      } else {
        // start tracking idle time
        localStorage.setItem(SESSION_IDLE_STATUS_KEY, SESSION_IDLE_STATES.ACTIVE);
        this.addListeners();
        const newExpiry = this.resetSessionExpiry();
        const currentDate = Date.now();
        const minimumTimeUntilNextActionableEvent = newExpiry - currentDate - this.sessionExpirationWarningDurationInMS;
        this.buildTimer(minimumTimeUntilNextActionableEvent);

        window.onbeforeunload = () => this.ngOnDestroy();
      }
    });
  };

  destroyServiceIfEmbeddedModeDetected() {
    this._sharedDataService.embeddedMode$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(embeddedMode => {
        if (embeddedMode) {
          this.ngOnDestroy();
        }
      });
    this._sharedDataService.embeddedWorkflow$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(flow => {
        if (flow !== null) {
          this.ngOnDestroy();
        }
      })

  }


  buildTimer(timerOffset) {
    /**
     * The session expiration can only be extended by user interaction on any tab, but will never be shorted.
     * To avoid a long-running interval poll, we are deferring the first interval callback event to check session status
     * until the initial warning modal due time. After this waiting period, check session status on a 2-second interval.
     * When checking session status, if the next expected warning modal due time is more than 3 polling intervals in the
     * future, kill and rebuild the timer with a new deferred period.
     */
    timer(timerOffset, INTERVAL_PERIOD_IN_MS)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const sessionExpiry = parseInt(localStorage.getItem(SESSION_EXPIRATION_DATE_KEY), 10);
        const currentDate = Date.now();
        if (sessionExpiry < currentDate) {
          localStorage.setItem(SESSION_IDLE_STATUS_KEY, SESSION_IDLE_STATES.LOGOUT);
          this.logoutUser();
        } else if ((sessionExpiry - this.sessionExpirationWarningDurationInMS) < currentDate) {
          if (!this.warningModalOpen) {
            localStorage.setItem(SESSION_IDLE_STATUS_KEY, SESSION_IDLE_STATES.WARNING);
            this.openWarningModal();
          }
        } else {
          const minimumTimeUntilNextActionableEvent = sessionExpiry - currentDate - this.sessionExpirationWarningDurationInMS;
          if (minimumTimeUntilNextActionableEvent > (INTERVAL_PERIOD_IN_MS * 3)) {
            this.unsubscribe$.next(true);
            this.buildTimer(minimumTimeUntilNextActionableEvent);
          }
        }
      });
  }

  resetSessionExpiry() {
    const updatedSessionExpiry = Date.now() + this.inactiveSessionTimeoutInMS;
    localStorage.setItem(SESSION_EXPIRATION_DATE_KEY, String(updatedSessionExpiry));
    return updatedSessionExpiry
  }

  addListeners() {
    // Combine user interaction events into a single observable. Use 'auditTime' to gather events occurring over a 1.5s
    // period and process in batches. The callback is simply writing a new expiry to localstorage, so we don't need info
    // about individual events - we only need to know if any user interaction events have occurred in the previous period
    this.interactionEventsSub = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'scroll'),
      fromEvent(window, 'keydown')
    ).pipe(auditTime(USER_INTERACTION_DEBOUNCE_TIME))
      .subscribe(this.handleUserInteraction.bind(this));

    // listen to localstorage changes that originate from other tabs/windows
    window.addEventListener('storage', this.boundHandleStorageEvent)
  }

  handleUserInteraction(_) {
    if (localStorage.getItem(SESSION_IDLE_STATUS_KEY) !== SESSION_IDLE_STATES.WARNING) {
      this.resetSessionExpiry();
    }
  }

  handleStorageEvent(event) {
    // handle modal response and state changes across tabs
    if (event.storageArea === localStorage && event.key === SESSION_IDLE_STATUS_KEY) {
      const action = localStorage.getItem(SESSION_IDLE_STATUS_KEY);
      switch (action) {
        case SESSION_IDLE_STATES.LOGOUT:
          this.logoutUser();
          if (this.warningModalOpen) {
            this.closeModal();
          }
          break;
        case SESSION_IDLE_STATES.WARNING:
          if (!this.warningModalOpen) {
            this.openWarningModal();
          }
          break;
        case SESSION_IDLE_STATES.ACTIVE:
          if (this.warningModalOpen) {
            this.closeModal();
          }
          break;
      }
    }
  }

  openWarningModal() {
    this.warningModalOpen = true;
    this._popupService.open({
      componentType: SessionTimeoutWarningModalComponent,
      cssClass: 'session-timeout-warning-modal',
      inputs: {
        timerLifetimeInMS: parseInt(localStorage.getItem(SESSION_EXPIRATION_DATE_KEY), 10) - Date.now(),
      },
      outputs: {
        callback: (continueSession: boolean) => {
          this.closeModal();
          if (continueSession) {
            localStorage.setItem(SESSION_IDLE_STATUS_KEY, SESSION_IDLE_STATES.ACTIVE);
            this.resetSessionExpiry();
            this._apiService.send('POST', '/api/users/extend-session').subscribe();
          } else {
            localStorage.setItem(SESSION_IDLE_STATUS_KEY, SESSION_IDLE_STATES.LOGOUT);
            this.logoutUser();
          }
        }
      },
    }).then((popup: NgxPopupComponent) => {
      this.popupRef = popup
      this.popupRef.closable = false;
    });
  }

  closeModal() {
    if (this.popupRef) {
      this.popupRef.closable = true;
      this.popupRef.close();
      this.popupRef = null;
    }
    this.warningModalOpen = false;
  }

  logoutUser() {
    this._router.navigate(['/logout']).then(() => {
      this.destroyListeners()
    });
  }

  destroyListeners() {
    this.interactionEventsSub?.unsubscribe();
    window.removeEventListener('storage', this.boundHandleStorageEvent);
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  ngOnDestroy() {
    this.closeModal();
    this.destroyListeners();
  }
}
