import {Component, OnInit, Input, EventEmitter, Output, AfterViewInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Company } from '@models/company';
import { DataViewColumn, DataViewRow } from '@models/dataview';
import { Select2OptionData } from '@components/shared/select2/select2.interface';
import { DataFrameService } from '@services/dataframes.service';
import { AlertService } from '@services/alert.service';
import { NgxPopupService } from '@components/shared/ngx-popups/ngx-popups/ngx-popups';
import {
  DATE_FORMAT,
  SERVER_DATE_FORMAT,
  TAXONOMY_ITEM_IDS,
  STD_ID_MAPPER,
  SUPPORTED_CURRENCIES
} from '@utils/constants';
import * as moment from 'moment';
import { STATEMENT_BUILDER_OPERATORS } from '@components/main/borrower/statement-builder/model';
import { GrowthType, NormalBalance } from '@utils/enums';
import { NumberFormattingService } from '@services/number-formatting.service';
import { LaunchDarklyService } from '@services/launchdarkly.service';
import { ConfirmationPopupComponent } from '@components/shared/popups/confirmation/confirmation-popup.component';
import {finalize} from "rxjs/operators";

declare var bootstrap: any;

@Component({
  selector: 'app-generate-forecast-form',
  templateUrl: './generate-forecast-form.component.html',
  styleUrls: ['./generate-forecast-form.component.scss'],
})
export class GenerateForecastFormComponent implements OnInit, AfterViewInit {
  @Input() company: Company;
  @Input() columns: DataViewColumn[];
  @Input() rows: DataViewRow[];
  @Input() spreadingTemplateItems: any[];
  @Input() frameToBeEditedId?: any;
  @Output() closeGeneratePeriods = new EventEmitter<boolean>();

  subsArr$: Subscription[] = [];
  companyId: Number;
  statementDate: string = null;
  reportingInterval = '';
  projectionName = '';
  selectedReferenceFrameIndex: number = -1;
  taxonomyItemIdToGrowthRateMappings = {};
  taxonomyItemIdsInGrowthRateMappings = [];
  isFormComplete = false;
  isSubmitting = false;
  isLoading = false;

  reportingIntervalOptions: Select2OptionData[] = [
    {
      id: 'MONTHLY',
      text: 'MONTHLY'
    },
    {
      id: 'QUARTERLY',
      text: 'QUARTERLY'
    },
    {
      id: 'SEMI_ANNUALLY',
      text: 'SEMI_ANNUALLY'
    },
    {
      id: 'ANNUALLY',
      text: 'ANNUALLY'
    },
    {
      id: 'FISCAL_YTD',
      text: 'FISCAL_YTD'
    },
    {
      id: 'TTM',
      text: 'TTM'
    }
  ];

  growthTypeOptions = [
    {
      id: GrowthType.Factor,
      text: "%"
    },
    {
      id: GrowthType.Fixed,
      text: "$"
    },
    {
      id: GrowthType.ExactValue,
      text: "#"
    }
  ];

  periodOptions: Select2OptionData[] = [];
  taxonomyItemOptions: Select2OptionData[] = [];

  statementDateHasBeenChangedByUser = false;
  hasReferenceFrameBeenChangedByUser = false;

  taxonomyItemIdToValueMappingsFromReferencePeriod = {};

  currency: string;
  supportedCurrencies = SUPPORTED_CURRENCIES;


  // should recalculate check everytime
  // a new reference sheet is selected
  // a new line item is assigned a taxonomy item
  // a line item growth type is updated
  // a line item value is updated
  // a line item is deleted
  check = 0;

  // we will allow user to complete forecast if check balance is between -1 and 1, may want to make this configurable later
  CHECK_THRESHOLD = 1

  // this is kind of a silly variable name. But heres the deal
  // 1. we SHOULD check the balance sheet balance if a balance sheet exists for the reference frame (because otherwise there is nothing to balance)
  // 2. we CAN deterministically figure out the balance if the following Taxonomy items are found (REF_TOTAL_ASSETS, REF_TOTAL_LIABILITIES, and REF_TOTAL_EQUITY)
  // ^ if one of those doesnt exist, it is not possible to check the balance and therefore we'll just ignore the validation
  shouldAndCanCheckBalanceSheetBalance = false;
  shouldForceForecastsToBeBalanced = true;

  NUMERIC_PATTERN = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/;
  WHOLE_POSITIVE_INTEGER_PATTERN = /^\d+$/;

  GROWTH_TYPE_TO_VALUE_PLACEHOLDER_TEXT_MAPPINGS = {
    "FACTOR": "Percent growth/decay",
    "FIXED": "Dollar growth/decay",
    "EXACT_VALUE": "Enter exact value"
  }


  constructor(
    private _dataFrameService: DataFrameService,
    private _alertService: AlertService,
    private _launchDarklyService: LaunchDarklyService,
    private popupService: NgxPopupService,
    public numberFormattingService: NumberFormattingService,
  ) { }

  ngOnInit(): void {
    this.getFeatureFlags();
    this.columns.forEach((column, colIdx) => {
      const periodLabel = (column.statementDate + " - "  + column.preparationType + " - " + column.reportingInterval + (column.projectionName ? " - " + column.projectionName : ""));
      this.periodOptions.push({
        id: String(colIdx),
        text: periodLabel,
      })
    })
    this.addSpreadingTemplateItemsToTaxonomyItemOptions(this.spreadingTemplateItems);

    if (this.frameToBeEditedId){
      this._prefillEditForm()
    } else {
      this.currency = this.company.settings['currency'];
      this.addLineItem();
    }
  }

  ngAfterViewInit() {
    this.enableTooltips()
  }

  enableTooltips() {
    // Bootstrap tooltip initialization
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  _prefillEditForm() {
    this.isLoading = true
    this._dataFrameService.loadPrefillDataForForecastedFrame(this.frameToBeEditedId)
      .pipe(finalize(() => {this.isLoading = false}))
      .subscribe(res => {
        this.projectionName = res.projectionName;
        this.reportingInterval = res.reportingInterval;
        this._prefillSelectedReferenceFrame(res.selectedReferenceFramePeriodKeyUuid);
        this._prefillLineItemRows(res.lineItems);
        this.statementDate = res.statementDate;
        this.currency = res.currency;
      }, error => {
        console.error('failed to load prefill values: ', error);
      })
  }

  _prefillSelectedReferenceFrame(selectedReferenceFramePeriodKeyUuid){
    const refColIndex = this.columns.findIndex((col) => col.periodKeyUuid === selectedReferenceFramePeriodKeyUuid);
    this.updateSelectedReferenceFrameIndex(String(refColIndex));
  }

  _prefillLineItemRows(lineItems: any[]){
    lineItems.forEach(item => {
      const tempUuid = this.addLineItem();
      this.updateGrowthType(item.growthType, tempUuid);
      this.updateGrowthValue(item.amountValue, tempUuid);
      this.updateSelectedTaxonomyItemId(item.taxonomyItemId, tempUuid);
    })
  }

  getFeatureFlags() {
    this.shouldForceForecastsToBeBalanced = this._launchDarklyService.flags['force-forecasts-to-be-balanced'];
    this.subsArr$.push(this._launchDarklyService.flagChange.subscribe((flags) => {
      this.shouldForceForecastsToBeBalanced = flags['force-forecasts-to-be-balanced'];
    }));
  }

  addSpreadingTemplateItemsToTaxonomyItemOptions(spreadingTemplateItems) {
    for (const spreadingTemplateItem of spreadingTemplateItems) {
      if (spreadingTemplateItem.children && spreadingTemplateItem.children.length > 0) {
        this.addSpreadingTemplateItemsToTaxonomyItemOptions(spreadingTemplateItem.children);
      } else if (!spreadingTemplateItem.hasRollup && ['StandardItem', 'SubItem', 'GenericStandardItem'].includes(spreadingTemplateItem.className)) {
        this.taxonomyItemOptions.push({
          id: String(spreadingTemplateItem.standardLineItemId),
          text: spreadingTemplateItem.label
        })
      }
    }
  }

  addLineItem() {
    const uuid = uuidv4();
    const filteredTaxonomyItemOptions = this.taxonomyItemOptions.filter(option => !this.taxonomyItemIdsInGrowthRateMappings.includes(option.id));
    this.taxonomyItemIdToGrowthRateMappings[uuid.toString()] = {
      "growth_type": GrowthType.Factor,
      "value": null,
      "is_value_valid": true,
      'taxonomy_item_options': filteredTaxonomyItemOptions
    };
    this.taxonomyItemIdsInGrowthRateMappings.push(uuid.toString());
    this.setBalanceSheetCheck();
    this.setIsFormComplete();
    return uuid
  }

  removeLineItem(taxonomyItemId: string) {
    delete this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId];
    this.taxonomyItemIdsInGrowthRateMappings = this.taxonomyItemIdsInGrowthRateMappings.filter(id => id !== taxonomyItemId);
    this.setBalanceSheetCheck();
    this.setIsFormComplete();
  }

  updateSelectedTaxonomyItemId(updatedTaxonomyItemId: string, existingTaxonomyItemId: string) {
    if (updatedTaxonomyItemId !== existingTaxonomyItemId) {
      const existingGrowthData = this.taxonomyItemIdToGrowthRateMappings[existingTaxonomyItemId];
      delete this.taxonomyItemIdToGrowthRateMappings[existingTaxonomyItemId];
      // we need to maintain order of this array, so swap out the old taxonomy item with the new one in the same index
      const indexOfExistingItem = this.taxonomyItemIdsInGrowthRateMappings.indexOf(existingTaxonomyItemId);
      this.taxonomyItemIdsInGrowthRateMappings[indexOfExistingItem] = updatedTaxonomyItemId;
      this.taxonomyItemIdsInGrowthRateMappings = this.taxonomyItemIdsInGrowthRateMappings.filter(id => id !== existingTaxonomyItemId);
      this.taxonomyItemIdToGrowthRateMappings[updatedTaxonomyItemId] = existingGrowthData;
      this.updateTaxonomyItemOptions();
      this.setBalanceSheetCheck();
      this.setIsFormComplete();
    }
  }

  updateGrowthType(growthType: string, taxonomyItemId: string) {
    this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['growth_type'] = growthType;
    this.setBalanceSheetCheck();
    this.setIsFormComplete();
  }

  updateGrowthValue(growthValue: string, taxonomyItemId: string) {
    if (!this.NUMERIC_PATTERN.test(growthValue) && growthValue !== "-" && growthValue) {
      this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['is_value_valid'] = false;
    } else {
      this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['is_value_valid'] = true;
    }
    this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['value'] = growthValue;
    this.setBalanceSheetCheck();
    this.setIsFormComplete();
  }

  updateTaxonomyItemOptions() {
    for (const taxonomyItemId of this.taxonomyItemIdsInGrowthRateMappings) {
      const filteredTaxonomyItemOptions = this.taxonomyItemOptions.filter(option => option.id === taxonomyItemId || !this.taxonomyItemIdsInGrowthRateMappings.includes(option.id))
      this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['taxonomy_item_options'] = filteredTaxonomyItemOptions;
    }
  }

  updateReportingInterval(reportingInterval: string) {
    this.reportingInterval = reportingInterval;
    this.setIsFormComplete();
  }

  updateStatementDate(event) {
    this.statementDate = event.dateObject.formatted;
    this.setIsFormComplete();
    this.statementDateHasBeenChangedByUser = true;
  }

  updateProjectionName(projectionName: string) {
    this.projectionName = projectionName;
    this.setIsFormComplete();
  }

  updateSelectedReferenceFrameIndex(selectedReferenceFrameIndex: string) {
    this.selectedReferenceFrameIndex = Number(selectedReferenceFrameIndex);
    this.hasReferenceFrameBeenChangedByUser = true;
    this.updateAutofilledNewPeriodData();
    this.updateTaxonomyItemIdToValueMappingsFromReferencePeriod();
    this.setBalanceSheetCheck();
    this.setIsFormComplete();
  }

  setIsFormComplete(): void {
    if (
      this.reportingInterval &&
      this.statementDate &&
      this.selectedReferenceFrameIndex !== -1 &&
      this.projectionName &&
      // only disable form button, when the balance is off and the feature flag is on
      ((this.check < this.CHECK_THRESHOLD && this.check > -this.CHECK_THRESHOLD) || !this.shouldForceForecastsToBeBalanced)
    ) {
      // check if all taxonomy item ids are whole positive integers (they are no longer the default uuids)
      // also confirm all growth values are valid
      for (const taxonomyItemId of this.taxonomyItemIdsInGrowthRateMappings) {
        if (
          !(this.WHOLE_POSITIVE_INTEGER_PATTERN.test(taxonomyItemId)) ||
          !(this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['is_value_valid']) ||
          this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['value'] === null ||
          this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['value'] === ''
        ) {
          this.isFormComplete = false;
          return
        }
      }
      this.isFormComplete = true;
    } else {
      this.isFormComplete = false;
    }
  }

  triggerCloseGeneratePeriods(newStatementCreated=false) {
    this.closeGeneratePeriods.emit(newStatementCreated);
  }

  generateForecast() {
    // show warning/confirmation modal if the balance sheet is not balanced
    // if we force it to be balanced, this warning will never appear bc the balance must be within the threshold before button is enabled
    if (!(this.check < this.CHECK_THRESHOLD && this.check > -this.CHECK_THRESHOLD)) {
      this.popupService.open({
        componentType: ConfirmationPopupComponent,
        cssClass: 'modal-confirmation',
        inputs: {
          question: 'GENERATE FORECAST',
          text: 'The balance sheet is not balanced. Are you sure you want to generate this forecast?',
          confirmButtonText: 'Generate Forecast'
        },
        outputs: {
          callback: this.handleGenerateForecast.bind(this)
        },
      });
    } else {
      this.handleGenerateForecast(true);
    }
  }

  handleGenerateForecast(approved: boolean) {
    if (approved) {
      this.isSubmitting = true;
      let submitRequest$: Observable<any>;
      const sharedRequestArgs: [number, string, string, string, any, any, string, any] = [
        this.company.id,
        moment(this.statementDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
        this.reportingInterval,
        this.projectionName,
        this.colToFrameKey(this.columns[this.selectedReferenceFrameIndex]),
        this.transformTaxonomyItemIdToGrowthRateMappings(),
        this.currency,
        this._buildFormSnapshot(),
      ];
      if (this.frameToBeEditedId){
        submitRequest$ = this._dataFrameService.updateForecast(this.frameToBeEditedId, ...sharedRequestArgs);
      } else {
        submitRequest$ = this._dataFrameService.generateForecast(...sharedRequestArgs)
      }
      submitRequest$.subscribe((data) => {
        this._alertService.success('Statement Built!');
        this.triggerCloseGeneratePeriods(true);
        this.isSubmitting = false;
      }, (err) => {
        this._alertService.error(`${err.message}`);
        this.isSubmitting = false;
      })
    }
  }

  _buildFormSnapshot() {
    return {
      projectionName: this.projectionName,
      reportingInterval: this.reportingInterval,
      statementDate: this.statementDate,
      selectedReferenceFramePeriodKeyUuid: this.columns[this.selectedReferenceFrameIndex]?.periodKeyUuid,
      lineItems: Object.entries(this.taxonomyItemIdToGrowthRateMappings).map(([taxonomyItemId, data]) => {
        return {
          taxonomyItemId: taxonomyItemId,
          growthType: data["growth_type"],
          amountValue: data["value"]
        }
      }),
      currency: this.currency
    }
  }

  transformTaxonomyItemIdToGrowthRateMappings() {
    const finalTaxonomyItemIdsInGrowthRateMappings = {};
    for (const taxonomyItemId of this.taxonomyItemIdsInGrowthRateMappings) {
      // remove is_valid_value and taxonomy_item_options keys from mappings and convert values to be numeric
      let val = Number(this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['value']);
      // if growth type is factor, divide values by 100, bc thats how backend expects them
      if (this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['growth_type'] === GrowthType.Factor) {
        val = val / 100;
      }

      const growthType = this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]['growth_type'];
      finalTaxonomyItemIdsInGrowthRateMappings[taxonomyItemId] = {
        'growth_type': growthType === GrowthType.ExactValue ? "" : growthType,
        'value': val,
      }
    }
    return finalTaxonomyItemIdsInGrowthRateMappings;
  }

  getBuilderFrameForColumn(column: DataViewColumn) {
    return {
      frame_key: this.colToFrameKey(column),
      operator: STATEMENT_BUILDER_OPERATORS.add
    }
  }

  colToFrameKey(column: DataViewColumn) {
    return {
      scenario: column.scenario,
      statement_date: moment(column.statementDate, DATE_FORMAT).format(SERVER_DATE_FORMAT),
      reporting_interval: column.reportingInterval,
      preparation_type: column.preparationType,
      projection_name: column.projectionName,
    }
  }

  updateAutofilledNewPeriodData() {
    if (!this.statementDateHasBeenChangedByUser) {
      this.statementDate = this.columns[this.selectedReferenceFrameIndex].statementDate;
    }
  }

  updateTaxonomyItemIdToValueMappingsFromReferencePeriod() {
    this.taxonomyItemIdToValueMappingsFromReferencePeriod = {};
    const referenceColumn = this.columns[this.selectedReferenceFrameIndex];

    let balanceSheetSubsectionId = null

    this.rows.forEach((row, idx) => {
      if (row.lineItem.id === STD_ID_MAPPER.REF_TOTAL_ASSETS) {
        balanceSheetSubsectionId = row.lineItem.id;
      } else if (row.lineItem.id === STD_ID_MAPPER.REF_TOTAL_LIABILITIES) {
        balanceSheetSubsectionId = row.lineItem.id;
      } else if (row.lineItem.id === STD_ID_MAPPER.REF_TOTAL_EQUITY) {
        balanceSheetSubsectionId = row.lineItem.id;
      } else if ([TAXONOMY_ITEM_IDS.CASH_FLOW_STATEMENT, TAXONOMY_ITEM_IDS.INCOME_STATEMENT, TAXONOMY_ITEM_IDS.UCA_CASHFLOW_DIRECT, TAXONOMY_ITEM_IDS.UCA_CASHFLOW_INDIRECT].includes(row.lineItem.id)) {
        balanceSheetSubsectionId = null;
      }

      let rollupBehavior = null;
      if (balanceSheetSubsectionId !== null) {
        if (balanceSheetSubsectionId === STD_ID_MAPPER.REF_TOTAL_ASSETS) {
          rollupBehavior = row.rollupBehavior === '-' ? NormalBalance.Credit : NormalBalance.Debit;
        } else {
          rollupBehavior = row.rollupBehavior === '-' ? NormalBalance.Debit : NormalBalance.Credit;
        }
      }

      this.taxonomyItemIdToValueMappingsFromReferencePeriod[String(row.lineItem.id)] = {
        value: referenceColumn.cells[idx].calculatedValue !== null ? referenceColumn.cells[idx].calculatedValue.value : 0,
        normalBalance: rollupBehavior
      }
    })
  }

  setBalanceSheetCheck() {
    // first step to setting balance sheet check, is to get the balance before any line item growth has been applied
    // so search for total assets, total liabilities and total equity in the reference sheet and calculate the check from the
    let check = 0;
    if (
      this.taxonomyItemIdToValueMappingsFromReferencePeriod.hasOwnProperty(String(TAXONOMY_ITEM_IDS.BALANCE_SHEET)) &&
      this.taxonomyItemIdToValueMappingsFromReferencePeriod.hasOwnProperty(String(STD_ID_MAPPER.REF_TOTAL_ASSETS)) &&
      this.taxonomyItemIdToValueMappingsFromReferencePeriod.hasOwnProperty(String(STD_ID_MAPPER.REF_TOTAL_LIABILITIES)) &&
      this.taxonomyItemIdToValueMappingsFromReferencePeriod.hasOwnProperty(String(STD_ID_MAPPER.REF_TOTAL_EQUITY))
    ) {
      this.shouldAndCanCheckBalanceSheetBalance = true;
      check = this.taxonomyItemIdToValueMappingsFromReferencePeriod[String(STD_ID_MAPPER.REF_TOTAL_ASSETS)].value -
      this.taxonomyItemIdToValueMappingsFromReferencePeriod[String(STD_ID_MAPPER.REF_TOTAL_LIABILITIES)].value -
      this.taxonomyItemIdToValueMappingsFromReferencePeriod[String(STD_ID_MAPPER.REF_TOTAL_EQUITY)].value;
    } else {
      this.shouldAndCanCheckBalanceSheetBalance = false;
      return;
    }

    // now iterate through the taxonomyItemIdToGrowthRateMappings to calc the updated check balance after growth has been applied
    for (let taxonomyItemId in this.taxonomyItemIdToGrowthRateMappings) {
      if (this.taxonomyItemIdToValueMappingsFromReferencePeriod.hasOwnProperty(taxonomyItemId)) {
        const itemValueFromReferenceFrame = this.taxonomyItemIdToValueMappingsFromReferencePeriod[taxonomyItemId].value;
        const growthType = this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]["growth_type"];
        const value = this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]["value"];
        const isValueValid = this.taxonomyItemIdToGrowthRateMappings[taxonomyItemId]["is_value_valid"];

        if (
          value !== null &&
          this.taxonomyItemIdToValueMappingsFromReferencePeriod[taxonomyItemId].normalBalance !== null &&
          isValueValid
        ) {
          let updatedVal = 0;
          if (growthType === GrowthType.Fixed) {
            updatedVal = itemValueFromReferenceFrame + Number(value);
          } else if (growthType === GrowthType.Factor) {
            updatedVal = itemValueFromReferenceFrame * (1 + (Number(value) / 100));
          } else {
            updatedVal = Number(value);
          }

          // determine if row has a natural debit or credit balance
          // if it has a natural debit balance, add the diff between the updatedVal and itemValueFromReferenceFrame
          // if it has a natural credit balance, subtract the diff between the updatedVal and itemValueFromReferenceFrame
          const changeFromReferencePeriod = (updatedVal - itemValueFromReferenceFrame);
          if (this.taxonomyItemIdToValueMappingsFromReferencePeriod[taxonomyItemId].normalBalance === NormalBalance.Debit) {
            check += changeFromReferencePeriod;
          } else {
            check -= changeFromReferencePeriod;
          }
        }
      }
    }

    // finally, update the check balance
    this.check = check;
  }

  copyCheckToClipboard() {
    navigator.clipboard.writeText(String(this.check)).then(() => {
      this._alertService.success('Copied balance sheet check', "Value Copied", 2)
    }, (error) => {
      console.error('Async: Could not copy text: ', error);
    });
  }

  changeCurrency(currency: string) {
    this.currency = currency;
  }

}
