import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../services/user.service';
import { NgxPopupService } from '../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { ContactSupportComponent } from '../../shared/popups/contact-support/contact-support.component';
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe';
// @ts-ignore
import packageJson from '../../../../../package.json';
import { NgxPopupComponent } from '../../shared/ngx-popups/ngx-popups/ngx-popups';
import { RestComponent } from '../../developer/command/rest/rest.component';
import { CacheComponent } from '../../developer/command/cache/cache.component';
import { SharedDataService } from '@services/shared-data.service';
import { AuthenticationService } from '@services/authentication.service';
import { Company } from '../../../models/company';
import { UserGuideService } from '@services/user-guide.service';
import {PLATFORM_PERMISSIONS} from "@utils/constants";
import {BankSettingsService} from "@services/bank-settings.service";
import {
  ContactSupportEmailModalComponent
} from "@components/shared/popups/contact-support-email-modal/contact-support-email-modal.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
@AutoUnsubscribe('_subsArr$')
export class HeaderComponent implements OnInit {
  showManualReview = true;
  display: boolean;
  appVersion: string = packageJson.version;
  company: Company;
  title: String;
  bankId: String = null;
  numDocuments = 0;
  userHasMultipleTenants: boolean;
  _subsArr$: Subscription[] = [];
  showDocumentStatusButton: boolean;
  contactSupportEmail: string;

  constructor(
    public userService: UserService,
    public sanitizer: DomSanitizer, // Don't remove need in template
    private popupService: NgxPopupService,
    public _sharedDataService: SharedDataService,
    private _router: Router,
    public authenticationService: AuthenticationService,
    public userGuideService: UserGuideService,
    public _bankSettingsService: BankSettingsService
  ) { }

  ngOnInit() {
    this.showDocumentStatusButton = this.userService.user.hasUserPermission(PLATFORM_PERMISSIONS.VIEW_DOCUMENT_STATUS_TRACKER)
    this._subsArr$.push(
      this._sharedDataService.company$.subscribe(company => {
        this.company = company;
      })
    );

    this._subsArr$.push(
      this._sharedDataService.pageHeaderTitle$.subscribe(title => {
        this.title = title;
      })
    );

    this._subsArr$.push(
      this._sharedDataService.numParsingTrackerDocuments$.subscribe(numDocuments => {
        this.numDocuments = numDocuments;
      })
    );

    this._subsArr$.push(this._bankSettingsService.getPortalSettings().subscribe((data) => {
      this.contactSupportEmail = data['_portalSettings']['contactSupportEmail'];
    }));

    if (this.userService.hasMultipleTenants()) {
      this.bankId = this.userService.user.bank_id;
    }

    this.userHasMultipleTenants = this.userService.hasMultipleTenants();
  }

  user_name() {
    if (this.userService.user) {
      return this.userService.user.name;
    }
    return '';
  }

  /**
   * helper to open support window
   */
  openSupportWindow() {
    this.popupService.open({
      componentType: ContactSupportComponent,
      cssClass: 'modal-support'
    });
  }

  shouldDisplayManualReview(): boolean {
    return this.userService.isManualReviewer();
  }

  shouldDisplaySpreading(): boolean {
    return this.userService.isSpreader();
  }

  restCommander() {
    this.popupService.open({
      componentType: RestComponent,
      cssClass: 'modal-rest-commander',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  cacheCommander() {
    this.popupService.open({
      componentType: CacheComponent,
      cssClass: 'modal-cache-commander',
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
      }, {once: true});
    });
  }

  switchTenant() {
    this._router.navigate(['/switch-tenant'])
  }

  openContactSupportEmailModal() {
    this.popupService.open({
      componentType: ContactSupportEmailModalComponent,
      cssClass: 'contact-support-modal',
      inputs: {
        supportEmail: this.contactSupportEmail,
      },
    })
  }
}
