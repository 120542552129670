<ng-container *ngIf="canViewAnalyses; else NoAccessAnalysisPage">
  <div class="list-analyses-container">
      <ng-container *ngIf="areLoansShown">
          <div class="row bottom-margin">
              <div class="col-9">
                  <button type="button" class="btn btn-primary btn-rounded" (click)="addLoan()"><i class="fas fa-plus"></i> Create New Loan</button>
              </div>
          </div>


          <div class="row bottom-margin">
              <div class="col-12">
                  <div *ngIf="loansLoading">
                      <div class="loader__container">
                          <div class="loader" style="margin-top: 20px; margin-bottom: 20px"></div>
                      </div>
                  </div>
                  <p *ngIf="!loansLoading && loans.length === 0">
                      There are no loans created for this borrower
                  </p>
                  <table
                  *ngIf="loans.length > 0"
                  class="table table-bordered header-title">
                      <tr class="table-header">
                          <th>Loan</th>
                          <th>Principal</th>
                          <th>Interest</th>
                          <th>Start Date</th>
                          <th>Remaining Term</th>
                          <th>Payment Type</th>
                          <th></th>
                      </tr>
                      <tr *ngFor="let loan of loans; let i = index">
                          <td><a (click)="viewLoanDetails(loan)">{{loan.title}}</a></td>
                          <td class="align-right">{{loan.principal | currency: currency:'symbol':'0.0-0'}}</td>
                          <td>{{loan.interestRate | number: '1.2-2'}} %</td>
                          <td>{{loan.startDate | amFromUtc | amDateFormat : 'MM/DD/YYYY'}}</td>
                          <td>{{loan.paymentsRemaining}} months</td>
                          <td>{{loanService.displayFriendlyPaymentTypeName(loan.paymentType)}}</td>
                          <td class="menu-td">
                              <div class="opener-container clickable" (click)="openLoanMenu(i)">
                                  <i class="fas fa-chevron-circle-down"></i>
                              </div>
                              <ul
                                  class="dropdown-menu"
                                  *ngIf="i === openedLoanMenuIndex">
                                  <li><a class="dropdown-item" (click)="viewLoanDetails(loan)">View Loan Details</a></li>
                                  <li><a class="dropdown-item" (click)="editLoan(loan)">Edit Loan</a></li>
                                  <li><a class="dropdown-item" (click)="deleteLoan(loan, $event)">Delete Loan</a></li>
                                  <!-- <li><span>Add Note</span></li> -->
                              </ul>
                          </td>
                      </tr>

                  </table>
              </div>
          </div>
      </ng-container>

      <ng-container>
          <div class="row bottom-margin">
              <div class="col-9" *ngIf="!displayCreateAndDeleteAnalysisButtons()">
                  <span class="analysis-type-header">Single-Entity Analyses</span>
              </div>
              <div class="col-9" *ngIf="displayCreateAndDeleteAnalysisButtons()">
                  <button type="button" class="btn btn-primary btn-rounded" (click)="addAnalysis()"><i class="fas fa-plus"></i> Create New Analysis</button>
              </div>
          </div>

          <div class="row bottom-margin">
              <div class="col-12">
                  <table class="table table-bordered header-title">
                      <tr class="table-header">
                          <th>Analysis</th>
                          <th>Date Created</th>
                          <th>Proposed Loans</th>
                          <th>Total Proposed Debt</th>
                          <th></th>
                      </tr>
                      <ng-container *ngIf="analyses.length; else noAnalyses">
                        <tr *ngFor="let analysis of analyses; let i = index">
                          <td><a [routerLink]="['/companies/', company.uuid, 'analyses', analysis.uuid]"><button class="btn btn-link p-0">{{analysis.name}}</button></a></td>
                          <td>{{analysis.createdDate | amFromUtc | amDateFormat : 'MM/DD/YYYY'}}</td>
                          <td class="align-right">{{numProposedDebts(analysis)}}</td>
                          <td class="align-right">{{totalProposedDebt(analysis) | currency}}</td>
                          <td class="menu-td">
                              <div class="opener-container clickable" (click)="openAnalysisMenu(i)">
                                  <i class="fas fa-chevron-circle-down"></i>
                              </div>
                              <ul
                                  class="dropdown-menu"
                                  *ngIf="i === openedAnalysisMenuIndex">
                                  <li><a class="dropdown-item" [routerLink]="['/companies/', company.uuid, 'analyses', analysis.uuid]">Edit Analysis</a></li>
                                  <li><a class="dropdown-item" (click)="deleteAnalysis(analysis, i, $event);" *ngIf="displayCreateAndDeleteAnalysisButtons()">Delete Analysis</a></li>
                              </ul>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-template #noAnalyses>
                        <tr>
                          <td colspan="5" class="no-analyses-container">
                            <div *ngIf="!analysesLoading">
                                <app-table-empty-state
                                    [helpText]="noAnalysesHelpText"
                                ></app-table-empty-state>
                            </div>
                            <ng-container *ngIf="analysesLoading">
                              <div class="loader" style="margin-top: 20px; margin-bottom: 20px"></div>
                            </ng-container>
                          </td>
                        </tr>
                      </ng-template>
                  </table>
              </div>
          </div>
      </ng-container>

      <ng-container *ngIf="isGlobalCashflowShown">
          <div class="row bottom-margin">
              <div class="col-9" *ngIf="!displayCreateAndDeleteAnalysisButtons()">
                  <span class="analysis-type-header">Multi-Entity Analyses</span>
              </div>
              <div class="col-9" *ngIf="displayCreateAndDeleteAnalysisButtons()">
                  <button type="button" class="btn btn-primary btn-rounded" (click)="addGlobalCashflow()"><i class="fas fa-plus"></i> Create New Global Cash Flow</button>
              </div>
          </div>

          <div class="row bottom-margin">
              <div class="col-12">
                  <table class="table table-bordered header-title">
                      <tr class="table-header">
                          <th>Analysis</th>
                          <th>Date Created</th>
                          <th>Proposed Loans</th>
                          <th>Total Proposed Debt</th>
                          <th></th>
                      </tr>
                      <ng-container *ngIf="globalCashflowAnalyses.length; else noGcfAnalyses">
                        <tr *ngFor="let analysis of globalCashflowAnalyses; let i = index">
                          <td><a [routerLink]="['/companies/', company.uuid, 'global-cashflow-analyses', analysis.uuid]"><button class="btn btn-link p-0">{{analysis.name}}</button></a></td>
                          <td>{{analysis.createdDate | amFromUtc | amDateFormat : 'MM/DD/YYYY'}}</td>
                          <td class="align-right">{{numProposedDebts(analysis)}}</td>
                          <td class="align-right">{{totalProposedDebt(analysis) | currency}}</td>
                          <td class="menu-td">
                              <div class="opener-container clickable" (click)="openGlobalCashflowMenu(i)">
                                  <i class="fas fa-chevron-circle-down"></i>
                              </div>
                              <ul
                                  class="dropdown-menu"
                                  *ngIf="i === openedGlobalCashflowMenuIndex">
                                  <li><a class="dropdown-item" [routerLink]="['/companies/', company.uuid, 'global-cashflow-analyses', analysis.uuid]">Edit Analysis</a></li>
                                  <li><a class="dropdown-item" (click)="deleteGlobalCashflowAnalysis(analysis, i, $event);" *ngIf="displayCreateAndDeleteAnalysisButtons()">Delete Analysis</a></li>
                              </ul>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-template #noGcfAnalyses>
                        <tr>
                          <td colspan="5" class="no-analyses-container">
                            <div *ngIf="!globalCashflowAnalysesLoading">
                              <app-table-empty-state
                                [helpText]="noGcfAnalysesHelpText"
                              ></app-table-empty-state>
                            </div>
                            <ng-container *ngIf="globalCashflowAnalysesLoading">
                              <div class="loader" style="margin-top: 20px; margin-bottom: 20px"></div>
                            </ng-container>
                          </td>
                        </tr>
                      </ng-template>
                  </table>
              </div>
          </div>
      </ng-container>
  </div>
</ng-container>

<ng-template #NoAccessAnalysisPage>
  <borrower-alert [alertText]="noAccessErrorText"></borrower-alert>
</ng-template>
