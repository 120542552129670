<nav class="px-0 navbar navbar-expand-sm header"
     [ngClass]="_sharedDataService.currentSidebar === 'regular' ? 'sidebar-closed' : 'sidebar-open'">
    <div class="container-fluid g-0">
        <div class="navbar-text p-0 me-auto">
            <div class="company-name-and-label-container" *ngIf="company">
                <p class="borrower-name fs-block fs-exclude">{{company.name}}</p>
                <span *ngIf="company.portfolio" class="badge portfolio-label fs-block">{{company.portfolio.name}}</span>
            </div>
            <div *ngIf="!company">
                <p class="borrower-name">{{title}}</p>
            </div>
        </div>

        <ul class="navbar-nav">
            <li class="nav-item px-2" *ngIf="showDocumentStatusButton">
                <a
                    class="nav-link"
                    href="javascript:void(0);"
                    [satPopoverAnchor]="uploadStatusPopover"
                    (click)="uploadStatusPopover.toggle()"
                >
                    <div class="avatar">
                        <i class="far fa-check-square"></i>
                        <span *ngIf="numDocuments > 0" class="notification-badge"></span>
                    </div>
                </a>

            <sat-popover #uploadStatusPopover hasBackdrop verticalAlign="below">
                <app-upload-status-popover
                    (closePopover)="uploadStatusPopover.toggle()"
                    [isManualReviewer]="shouldDisplayManualReview()"
                    [isSpreader]="shouldDisplaySpreading()"
                >
                </app-upload-status-popover>
            </sat-popover>
        </li>
        <li class="nav-item dropdown px-2">
            <a href="#" id="nbAcctDD" class="nav-link" data-bs-toggle="dropdown">
                <div class="avatar">
                    <i class="far fa-question-circle"> </i>
                </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
                <span class="dropdown-item-text walkthrough-header" *ngIf="userGuideService.userGuides.length > 0"><strong>Articles</strong></span>
                <a class="dropdown-item" *ngFor="let userGuide of userGuideService.userGuides" target="_blank"
                   href="{{userGuide.url}}">
                    {{userGuide.name}}
                </a>
                <div class="dropdown-divider"
                     *ngIf="userGuideService.userGuides.length > 0"
                     role="separator"></div>
                <a class="dropdown-item user-guide" target="_blank" href="https://numeratedcrm.my.site.com/support/s/">
                    <i class="fas fa-book"></i> User Guide
                </a>
                <ng-container *ngIf="contactSupportEmail">
                    <div class="dropdown-divider" role="separator"></div>
                    <a class="dropdown-item contact-support-option" (click)="openContactSupportEmailModal()">
                        <i class="fas fa-question-circle"></i>Contact Support
                    </a>
                </ng-container>
            </div>
        </li>
        <li class="nav-item dropdown px-2">

                <a href="#" id="nbAcctDD" class="nav-link" data-bs-toggle="dropdown">
                    <div class="avatar">
                        <i *ngIf="!userService.user || !userService.user.img" class="fas fa-user-circle"></i>
                        <img *ngIf="userService.user && userService.user.img"
                             [src]="sanitizer.bypassSecurityTrustResourceUrl(userService.user.img)">
                    </div>
                    {{user_name()}}
                    <div class="arrow-down">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" [routerLink]="['/logout']">Log Out</a>
                    <a class="dropdown-item version">Version <span class="current-version-number">{{appVersion}}</span></a>
                    <a class="dropdown-item bankId" *ngIf="bankId">Tenant Id: {{bankId}}</a>
                    <div class="dropdown-divider" *ngIf="userHasMultipleTenants" role="separator"></div>
                    <a class="dropdown-item switch-tenant" *ngIf="userHasMultipleTenants" (click)="switchTenant()">
                        <i class="fas fa-university"></i> Switch Tenant
                    </a>
                </div>
            </li>
        </ul>
    </div>
</nav>
