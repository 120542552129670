<li class="template-item"
  [ngClass]="{
    'source': !row.template,
    'header': row.header,
    'calculation': row.calculated,
    'hidden': isRowHidden
  }"
>
  <div class="main-row">
    <div class="line-item-label" [ngClass]="{'collapsable': childrenCollapsable}" data-bs-toggle="collapse" [attr.data-bs-target]="'#'+row.ref">
      <span class="right-caret" *ngIf="childrenCollapsable"></span> {{(row.template || viewInNativeLanguage) ? row.label : row.translatedLabel}} <span *ngIf="childrenCollapsable">({{getSourceItems().length}})</span>
    </div>
    <div class="value-container" *ngIf="!row.header" [ngClass]="{
      'column_valid': columnCheckOk,
      'valid': (row.validationRow || row.lineItemId === 61100) && rowIsValid(row),
      'invalid': (row.validationRow || row.lineItemId === 61100) && !rowIsValid(row)
    }">
      <div *ngIf="selectedColumn >= 0 && row.cells && row.cells[selectedColumn]">
        {{row.cells[selectedColumn].itemRowValue || row.cells[selectedColumn].value | number:'1.2-2'}}
      </div>
      <div class="subtext" *ngIf="selectedColumn >= 0 && row.cells[selectedColumn] && row.cells[selectedColumn].flag">
          <span *ngIf="row.cells[selectedColumn].offBy > 0">+</span>{{row.cells[selectedColumn].offBy | number:'1.2-2'}}
      </div>
        <div class="goal-seek-button" *ngIf="selectedColumn >= 0 && enableGoalSeek && row.cells[selectedColumn] && row.cells[selectedColumn].flag">
            <button (click)="hideGoalSeek($event)" class="btn btn-outline-secondary btn-sm mt-1" *ngIf="isGoalSeekToolTipVisible && row.lineItemId === visibleSuggestionsOriginId && goalSeekCount > 0; else showButton">
                <span class="btn-label">Hide Spread Assist ({{ goalSeekCount }})</span>
            </button>
            <ng-template #showButton>
                <button (click)="startGoalSeek($event, row)" class="btn btn-outline-secondary btn-sm mt-1">
                    <span class="btn-label">Spread Assist</span>
                    <div *ngIf="row.goalSeekInProgress" class="loader button-loader"></div>
                </button>
            </ng-template>
        </div>
    </div>
    <div class="badge-container">
      <div *ngIf="!row.template">
        <span class="fas fa-times remove-cat-button" (click)="removeCategorization($event, row)"></span>
      </div>
    </div>
  </div>
  <ul *ngIf="sourceChildren" [ngClass]="{'collapse': childrenCollapsable}" class="nested-template-items" [id]="row.ref">
    <app-spreading-template-row
      *ngFor="let child of getSourceItems()"
      [row]="child"
      [columnCheckOk]="columnCheckOk"
      (clearedCategorization)="removeCategorizationChild($event)"
      [selectedColumn]="selectedColumn"
      [viewInNativeLanguage]="viewInNativeLanguage"
    ></app-spreading-template-row>
  </ul>
  <ul *ngIf="subChildren || row.rollupName" class="nested-template-items">
    <app-spreading-template-row
      *ngFor="let child of subChildren"
      [row]="child"
      [columnCheckOk]="columnCheckOk"
      (clearedCategorization)="removeCategorizationChild($event)"
      [selectedColumn]="selectedColumn"
      [viewInNativeLanguage]="viewInNativeLanguage"
    ></app-spreading-template-row>
    <li class="template-item calculation" *ngIf="row.rollupName">
      <div class="main-row">
        <div class="line-item-label">
          {{row.rollupName}}
        </div>
        <div class="value-container" [ngClass]="{
          'column_valid': columnCheckOk,
          'valid': row.validationRow && rowIsValid(row),
          'invalid': row.validationRow && !rowIsValid(row)
        }">
          <div *ngIf="selectedColumn >= 0 && row.cells && row.cells[selectedColumn]">
            {{row.cells[selectedColumn].value | number:'1.2-2'}}
          </div>
          <div class="subtext" *ngIf="selectedColumn >= 0 && row.cells[selectedColumn] && row.cells[selectedColumn].flag">
              <span *ngIf="row.cells[selectedColumn].offBy > 0">+</span>{{row.cells[selectedColumn].offBy | number:'1.2-2'}}
          </div>
          <div class="goal-seek-button" *ngIf="selectedColumn >= 0 && enableGoalSeek && row.cells[selectedColumn] && row.cells[selectedColumn].flag">
            <button (click)="hideGoalSeek($event)" class="btn btn-outline-secondary btn-sm mt-1" *ngIf="isGoalSeekToolTipVisible && row.lineItemId === visibleSuggestionsOriginId && goalSeekCount > 0; else showButton">
                <span class="btn-label">Hide Spread Assist ({{ goalSeekCount }})</span>
            </button>
            <ng-template #showButton>
                <button (click)="startGoalSeek($event, row)" class="btn btn-outline-secondary btn-sm mt-1">
                    <span class="btn-label">Spread Assist</span>
                    <div *ngIf="row.goalSeekInProgress" class="loader button-loader"></div>
                </button>
            </ng-template>
          </div>
        </div>
      </div>
    </li>
  </ul>
</li>
